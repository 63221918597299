<template>
  <div>
    <Navbar title="Researchers' Resources" :hideSearch="false" />
    <div class="">
      <iframe src="https://outline.com/" style="width:100%;height:100vh">
      </iframe>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import { orderBy, filter } from "lodash";

export default {
  name: "Outline",
  components: { Navbar, Footer },
  data() {
    return {
      isLoading: false,
      category: "image",
      sort: "title-asc",
      sortName: "Title (A-Z)",
      showSideMenu: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        perPage: 5
      },
      // external resources view variables
      externalResourceType: "JSTOR",
      filteredItems: undefined,
      searchQuery: undefined,
      searchType: "title",
      tableProps: {
        columnTitle: [
          "Type",
          "Title",
          "Subject",
          "Author(s)",
          "Publication Year"
        ],
        columnData: ["type", "title", "subject", "authors", "publicationYear"],
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      },
      modals: {
        showViewDetailModal: false,
        showDeleteItemModal: false,
        viewModalTitles: [
          "Type",
          "Title",
          "Subject",
          "Description",
          "Keywords",
          "Authors",
          "Publication Year",
          "Source URL"
        ],
        viewModalTitlesData: [
          "type",
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "sourceURL"
        ]
      },
      itemDetail: undefined
    };
  },
  computed: {
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    },
    items() {
      return orderBy(
        this.$store.state.externalResource,
        ["dateCreated"],
        ["desc"]
      );
    }
  },
  watch: {
    items: function(val) {
      this.filteredItems = val;
    }
  },
  async created() {
    if (this.$route.query.externalResourceType) {
      this.externalResourceType = this.$route.query.externalResourceType.toString();
    }
    this.tableProps.isLoading = true;
    await this.$store.dispatch("getExternalResource");
    this.filteredItems = this.items;
    this.tableProps.isLoading = false;
    this.filterItems();
  },
  methods: {
    openViewDetailModal(selectedItem) {
      this.itemDetail = selectedItem;
      this.modals.showViewDetailModal = true;
    },
    closeViewDetailModal() {
      this.modals.showViewDetailModal = false;
    },
    filterItems() {
      if (this.searchQuery === "") {
        this.filteredItems = this.items;
      } else {
        this.filteredItems = filter(this.items, {
          type: this.externalResourceType
        });

        this.filteredItems = filter(this.items, item => {
          return item[this.searchType]
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.box-anim:hover {
  transition: transform 125ms;
  transform: scale(1.05);
  -webkit-box-shadow: 0 0.5em 1em -0.125em #808080;
  box-shadow: 0 0.5em 1em -0.125em #808080;
}
</style>
